import type { ComposerTranslation } from 'vue-i18n'
import type { MenuItem } from '@/types/Global/MenuItem'

export function useMenuItemService() {
  const initMainMenu = (t: ComposerTranslation) => {
    const mainMenu: MenuItem[] = []
    mainMenu.push({
      title: t('views.nofocus.global.menu.main-menu.items.project-overview'),
      icon: 'mdi-format-list-bulleted',
      link: '/project-overview',
      id: 0,
      active: false
    })
    mainMenu.push({
      title: t('views.nofocus.global.menu.main-menu.items.subject'),
      icon: 'mdi-clipboard-text',
      link: '/subject',
      id: 1,
      active: false
    })
    mainMenu.push({
      title: t('views.nofocus.global.menu.main-menu.items.country'),
      icon: 'mdi-flag',
      link: '/country',
      id: 2,
      active: false
    })
    mainMenu.push({
      title: t('views.nofocus.global.menu.main-menu.items.whitelist'),
      icon: 'mdi-format-list-checks',
      link: '/whitelist',
      id: 3,
      active: false
    })
    mainMenu.push({
      title: t('views.nofocus.global.menu.main-menu.items.languages'),
      icon: 'mdi-translate',
      link: '/language',
      id: 3,
      active: false
    })

    return mainMenu
  }

  return { initMainMenu }
}
